.wini{
    background-image: url(/src/css/wini.jpg);
    background-attachment: fixed;
    min-height: 100vh;
    background-size: cover;
    justify-content: center;
    align-items: center;

    }

    .auths{
    background-image: url(/src/css/fondx.jpg);
    min-height: 100vh;
    background-size: cover;
    justify-content: center;
    align-items: center;
    }

    .car{
        background-image: url(/src/css/bb.jpg);
        min-height: 100vh;
        background-size: cover;
        justify-content: center;
        align-items: center;
        }
    .authss{
        background-image: url(/src/css/fondx.jpg);
        min-height: 100vh;
        background-size: cover;
        justify-content: center;
        align-items: center;
        }


        .displ{
            display: none;
        }
    .versement{
        
        
        
        width: 100%;
        min-height: 120vh;
        height: auto;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),url(/src/css/versement.jpg);
        background-size: cover;
        background-position: center;
        padding: 0 5%; 
    }
   
   
    
    .card-image{
        position: relative;
        height: 100px;
        width: 200px;
        border-radius: 50%;
        background: #fff;
        padding: 3px;
        
    }
    .card-img {
        height: 70%;
        width: 70%;
        object-fit: cover;
        border-radius: 50%;
        border: 4px solid #4070f4;
    }
    
    .card{
        border-radius: 25px;
        background-color: gold;
    }
.oi{
    color: gold;
}