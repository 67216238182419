.barHaut {
    background-color: rgb(6, 122, 116);
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 10px 70px;
    position: relative;
}

.footer{
    background-color: rgb(6, 122, 116);
    min-height: 170px;
    height: auto;
   
    
    padding: 10px 70px;
    position: relative;
}

.pC{
    background-color: rgb(6, 122, 116);
    height: 40px;
    text-align: center;
    color: white;
    font-size: 30px;
}

.vT{
    color:  white;
    text-align: center;
    font-family: Arial, sans-serif; /* Utilisez une police sans empattement pour la clarté */
    font-size: 20px; /* Taille de la police */
  
    font-weight: bold;
   
   
}

.vTc{
    color: white;
}


.propos{
    width: 190px;
    color: white;
}
.footS{
    display: flex;
    justify-content: space-between;
}
.oblo{
    
    color: black;
}
.block{
    font-size: 12px;
}
.copy{
    text-align: center;
    color: white;
}
.lisettt{
    color: white;
    font-size: 12px;
}

.user{
    height: 60px;
    
    width: 60px;
    border-radius: 50%;
    margin-right: 15px;
}


.userLog{
    height: 45px;
    
    width: 45px;
    border-radius: 50%;
    margin-right: 15px;
}
.lep{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hero{
    display: block;
    color: white;
}
.nom{
    color: white;
}
.lep2{
    display: flex;
    align-items: center;
}


.poBut{
    display: flex;
    justify-content: space-around;
  
   }
@media all and(max-width:1024px){
    
    .sex{
        position: relative;
        color: #e5e5e5;
        text-transform: capitalize;
        font-size: 24px;
        margin-left: -160px;
        top: -20px;
      } 
   
     
      .sex1{
        position: relative;
        color: #e5e5e5;
        text-transform: capitalize;
        font-size: 24px;
        margin-left: -160px;
        top: -20px;
      }
}
@media all and (max-width: 860px){
    .respon{
    
   height: 60px;
        display: flex;
        justify-content:space-between;
        
        
        
    }

    .cru{
        display:flex;
        justify-content: space-around;
    }
    .footS{
        display: block;
    }
  
    .hpp{ 
        color: white; 
       }
   
   .disposition{
    display:block;
    
   }

   .pofille{
   
    flex: 0.75;
    background-color: rgb(41, 40, 40) ;
    min-height: 50vh;
   
    
   }
   .poBut{
    display: flex;
    justify-content: space-around;
   }
   .option{
   flex: 5;
   }
   .compon{
       flex: 1;
       
   }
   .h{
    display: contents;
   }
   .ho{
    display: contents;
   }
 
   .biann{
    display: block;
    
    align-items: center;
   
    padding: 10px 70px;
}

.itec{
    display: block;
   
    
}
.space{
    display: block;
   
    
}



thead {
    display: none;
    }
    .win{
        display: none;
    }
    .person{
        display: block;
        
    }
    .izi{
        display: none;
    }
    .oix{
        height: 28px;
        width: auto;
        border-radius: 7px;
        border: none;
    }
    tr{
        display: block;
        margin-bottom: 20px;
        }
        .deco{
            display: none;
        }

        td {
            display: block;
            text-align: right;
            
            }

            td:before {
                content: attr(data-label);
                float: left;
                font-weight: bold;
                }
    
    .rev{
margin-left: -340px;
margin-top: 300px;
    } 

 .redmi{
    margin-left: -340px;
margin-top: 300px;
 }
   .form-title{
    display: none;
   }
    .cod{
        margin-left: -350px;
margin-top: 400px;
width: 300px;
padding: 60px;
    }
    .ess{
        margin: 0px;
        margin-left: 160px;
        margin-top: 70px;
        width: auto;
       
    }
  
    .enregistrercond{
        margin: 0px;
        margin-left: 130px;
        margin-top: 15px;
        width: auto;
    }
    .pre{
        margin: 50px;
       padding: 50px;
        width: 300px;

    }
    .sex{
        position: relative;
        color: #e5e5e5;
        text-transform: capitalize;
        font-size: 24px;
        margin-left: -160px;
        top: -20px;
      } 
   
     
      .sex1{
        position: relative;
        color: #e5e5e5;
        text-transform: capitalize;
        font-size: 24px;
        margin-left: -160px;
        top: -20px;
      }
    
  
   
    .somi{
        width: auto;
        font-size: 18px;
    }
   
    .wini{
        background-image: url(/src/css/ca.jpg);
        
        min-height: 100vh;
        background-size: cover;
        justify-content: center;
        align-items: center;
    
        }
        .auths{
            background-image: url(/src/css/vtest.jpg);
            min-height: 100vh;
            background-size: cover;
            justify-content: center;
            align-items: center;
            }
        .versement{
        
        
        
            width: 100%;
            min-height: 120vh;
            height: auto;
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),url(/src/css/pca.jpg);
            background-size: cover;
            background-position: center;
            padding: 0 5%; 
        }

    .dawin{
            
            height: 100vh;
            width: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),url(/src/css/av.jpg);
            background-size: cover;
            background-position: center;
            padding: 0 5%;   
    
}

.cieux {
    position:unset;
	padding-top: 80%;
	color: #fff;
    
}
    
   
    .che{
        font-size: 13px;
    }
    .sel{
        width: 130px;
    }
    .seles{
        width: 130px;
        margin-left: -250px;
    }
    .autreSel{
        width: 130px;
    }
    .autre{
        margin-left: -600px;
        margin-top: -11px;
    }
    .recherche{
        width: auto;
    }
   
  
    .user{
        height: 30px;
    
        width: 30px;
        border-radius: 50%;
        margin-left: -100px;
      
    }
  
    .nom{
      font-size: 10px;
      margin-left: -110px;
      display: none;
    }
    .post{
        display: none;
font-size: 5px;


    }
   .barHaut{
    height: 50px;
    padding: 0px;
    justify-content: space-between;
    align-items:center;
   
   }
   
   .pr{
    width: auto;
    height: 30px;
    font-size: 12px;
   }
   .cieux p{
    font-size: 50px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   }
   .cieux h2{
       font-size: 40px;
       margin-left: 4px;
   }
  
 }

 
.ninh{
    
    background: gold;
}


.field{
    width: 50%;
    margin: auto;
}
.legend{
    text-align: center;
}


.menu-bars{
    
    margin-left: 2rem;
    font-size: 2.5rem;
    background: none;
    
} 
.trois{
    display: none;
}
.nav-menu {
    background-color: rgb(6, 122, 116);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 20;
}
.nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 20;
}
.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 50px;
}
.nav-text a{
    text-decoration: none;
    color:#f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}
.nav-text a:hover {
    background-color: #1a83ff;

}
.nav-menu-items {

    width: 100%;
}
.navbar-toggle {
   
    width: 100%;
    height: 50px;
    display: flex; 
    justify-content: flex-start;
    align-items: center;
}
span {
    margin-left: 16px;
}

.imo{
    height: 90px;
    
    width: 90px;
    border-radius: 50%;
}
.if{
    height: 60px;
    width: 60px;
}
.am{
    height: 20px;
    width: 20px;
    background: transparent;
    padding: 4px;
}
.deco{
    color: rgba(0, 0, 0, 0.675);
    font-size: 15px;
    font-weight: bold;
    
}
.sub-menu-wrap{
    position: absolute;
    top: 100%;
    right: 10%;
    width: 320px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s;
}

.sub-menu-wrap.open{
    max-height: 400px;
}

.sub-menu{
    background: #3d3f50;
    padding: 20px;
    margin: 10px;
}
.user-info{
    display: flex;
    align-items:center;
}
.user-info h3{
    font-weight: 500;
}
.user-info img{
    width: 60px;
    border-radius: 50%;
    margin-right: 15px;
}
.sub-menu hr{
    border: 0;
    height: 1px;
    width: 100%;
    background: #ccc;
    margin: 15px 0 10px;
}
.link{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 15px 0;
    
}
.autre{
    display: flex;
    justify-content: space-between
}
.hero{
    display: block;
    color: white;
}
.hero2{
display: block;
}
.link p{
    width: 100%;
}
.link span{
    font-size: 22px;
    transition: transform 0.5s;
}
.link:hover span{
    transform: translateX(5px);
}
.link:hover p{
    font-weight: 600;
}
.le2{
    display: block;
}
.linko{
    color: green;
 
}
.linko:hover{
    color: midnightblue;
}
.somme{
    color: green;
}

.modal{
    z-index: 1002; /* Assurez-vous que la modale est au-dessus des autres éléments */
}

.modal, .overlay{
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;

}

.overlay{
    background: rgba(49,49,49,0.8);
}

.modal-content{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: grey;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    color: white;
}

.modal2, .overlay2{
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}
.overlay2{
    background: rgba(49,49,49,0.8);
}
.modal-content2{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: grey;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 900px;
    min-width: 500px;
    
    
}
.close-modal{
    position: absolute;
    top: -5px;
    right: -3px;
    padding: 5px 7px;
    height: 30px;
    width: 30px;
}

.order{

    text-align: center;
}
.sous-order{
    display: flex;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.284);
}
.oryon{
    text-align: center;
    color: rgb(6, 122, 116);
}
.useName{
    color: white;
}

.timus{
    display: flex;
    justify-content: space-around;
}
.icon{
    background:  rgb(6, 122, 116);
    display: flex;
    justify-content: space-between;
}
