.main {
    width: 100%;
    height: 100vh;
    display: flex;
    text-align: center;
}
.menue {
    width: 10%;
    background-color: #121227;
    
  padding: 30px;
  
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

}
.menue ul{
    
    
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    display: list-item;
    padding: 20px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    line-height: 60px;

}
a {
    text-decoration: none;
}

  