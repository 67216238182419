.azer {
    color: rgb(46, 0, 252);
}


.search{
    display: flex;
    width: 400px;
  
    height: 38px;
    
    color: white;
    
    
}
.searchMod{
    width: 170px;
  
    height: 30px;
    
    color: white;

}
.recherche{
     text-align: center;
    border-radius:25px;
    width: 100%;
     color: black;
     background-color: white;
     border: red;
}

.rechercheMod{
    text-align: center;
   border-radius:25px;
   width: 100%;
   height: 35px;
    color: black;
    
    background-color: white;
    border: red;
}

.selvil{
  /*  width: 38%;*/
  
    
  
  box-sizing: border-box;
  background: transparent;
}
.aff{
    display: flex;
    justify-content: space-between;
    
}
.inove{
     
     display: flex;
     width: 100%;
     justify-content: space-between;
     
     
}

.sel{
    height: 38px;
    width: 400px;
    border-radius:25px;
    text-align: center;
    border: none;
}
.seles{
    height: 38px;
    width: 400px;
    border-radius:25px;
    text-align: center;
    border: none;
}
.sele{
    
    height: 38px;
    width: 200px;
    
    border-radius:25px;
    text-align: center;
    border: none;
}
.autreSel{
    height: 38px;
    width: 200px;
    border-radius:25px;
    text-align: center;
}
.che{
    color: white;
    text-align: center;
}
.person{
    display: flex;
    justify-content: space-between;
}
.oix{
    height: 28px;
    width: 200px;
    border-radius: 7px;
    border: none;
}
.oix2{
    height: 25px;
    width: 150px;
    border-radius: 7px;
    border: none;
}
.oixe{
    height: 28px;
    width: 150px;
    border-radius: 7px;
    border: none;
}
.popo{
    display: flex;
    justify-content: space-around;
}
.somi{
    background-color: midnightblue;
    color: white;
    border-radius: 7px;
    border: none;
    width: 80px;
}
.somi2{
    background-color: midnightblue;
    color: white;
    border-radius: 7px;
    border: none;
    width: 90px;
    height: 25px;
}
.somi:hover{
    background-color: green;
}
.somi2:hover{
    background-color: green;
}
.somi:active{
    background-color: midnightblue;
}
.somi2:active{
    background-color: midnightblue;
}
.disposition{
    display: flex;
    width: 100%;
}
.pofille{
   
   
   
    
    
   
    

    
 flex: 1.3;
 background-color: rgb(41, 40, 40) ;
 min-height: 100vh;

 
}
.option{
flex: 5;
}
.compon{
    flex: 1;
    
}
.playProfil{
    
    
    border: 1px solid #666;
    
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    padding: 35px;
    background-color:rgb(41, 40, 40) ;
}
.menuBar{
    background-color: black;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 35px;
    position: relative;
}
.osi{
    font-size: 20px;
    margin: -8px;
    color: white;
}
.adro{
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid #666;
    background-color:rgb(41, 40, 40) ;
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 4px;
    
}
.ligne{
    width: 100%;
    border-bottom: 2px solid whitesmoke;
}
.buto5{
   border:none;
   border-color: gray;
}
.ajoutons{
    background-color: rgb(114, 244, 114);
    width: 100px;
    height: 50px;
    border: none;
}
.ajoutons:hover{
    background-color: green;
}
.ajoutons:active{
    background-color: rgb(114, 244, 114);
}

.buPe{
    display: inline-block;
    background-color: blue;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 22px;
    padding: 80px;
    width: 400px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    margin-left: 280px;
    margin-top: 100px;
    border: none;
    
}
.retrait{
    border: none;
    width: 100px;
    background-color: rgb(0, 128, 81);
    color: white;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    margin: 10px;
}
.retrait:hover{
    background-color: green;
}
.retrait:active{
    background-color: rgb(0, 128, 81);
}
.pr{
    width: 100px;
    height: 70px;
    background-color:transparent;
    border: none;
    color: white;
    cursor: pointer;
}
.dirResult{
    background-color: gray;
}