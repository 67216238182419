

      

.body{
    display: flex;
    height: 115vh;
    justify-content: center;
    align-items: center;

    background-size: cover;
}

.container{
    width: 100%;  
    background: rgba(0, 0, 0, 0.5);
    padding: 28px;
    margin: 0 280px;
    border-radius: 10px;
    box-shadow: inset -2px 2px 2px white;
}
.containers{
    width: 80%;  
    background: rgba(0, 0, 0, 0.5);
    padding: 28px;
    margin: 0 100px;
    border-radius: 10px;
    box-shadow: inset -2px 2px 2px white;
}
.containers2{
    width: 40%;  
    background: rgba(0, 0, 0, 0.5);
    padding: 28px;
    margin: 0 100px;
    border-radius: 10px;
    box-shadow: inset -2px 2px 2px white;
}
.pV{
    display: flex;
}
.upRadio{
    height: 40px;
    width: 80%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    padding: 0 10px;
}
.form-title{
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 6px;
    color: white;
    text-shadow: 2px 2px 2px black;
    border-bottom: solid 1px white;
}

.main-user-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
 
}

.user-input-box:nth-child(2n){
    justify-content: end;
}

.user-input-box{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding-bottom: 15px;
}

.libe{
    
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 15px;
}

.libe input{
    height: 40px;
    width: 100%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    padding: 0 10px;
}

.libe label{
    width: 95%;
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin: 5px 0;
}

.user-input-box label{
    width: 95%;
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin: 5px 0;
}

.user-input-box input{
    height: 40px;
    width: 80%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    padding: 0 10px;
}


.user-input-box select{
    height: 40px;
    width: 80%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    padding: 0 10px;
}

.gender-title{
    color:white;
    font-size: 24px;
    font-weight: 600;
    border-bottom: 1px solid white;
}

.gender-category{
    margin: 15px 0;
    color: white;
}

.gender-category label{
    padding: 0 20px 0 5px;
}

.gender-category label,
.gender-category input,
.form-submit-btn input{
    cursor: pointer;
}

.form-submit-btn{
    margin-top: 40px;
}

.form-submit-btn input{
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
    padding: 10px;
    border:none;
    border-radius: 3px;
    color: rgb(209, 209, 209);
    background: rgba(63, 114, 76, 0.7);
}

.buttonForDel{
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
    padding: 10px;
    border:none;
    border-radius: 3px;
    color: rgb(209, 209, 209);
    background: rgba(209, 20, 20, 0.7);
}

.buttonForDel:hover{
    background: rgba(248, 7, 7, 0.7);
    color: rgb(255, 255, 255);
}

.buttonForDel {
    cursor: pointer;
}



.form-submit-btn input:hover{
    background: rgba(56, 204, 93, 0.7);
    color: rgb(255, 255, 255);
}

@media(max-width: 600px){
    .body{
        display: flex;
        height: 85vh;
        justify-content: center;
        align-items: center;
    
        background-size: cover;
    }

    
.user-input-box label{
    width: 95%;
    color: white;
    font-size: 16px;
    font-weight: 300;
    margin: 5px 0;
}

    .container{
        
        min-width: 310px;
        width: auto;
    }
    .wri{
        font-size: 15px;
        color: white;
    }
    .containers{
        
        min-width: auto;
        margin: 0 50px;
    }
}

    .user-input-box{
        margin-bottom: 12px;
        width: 50%;
    }

    .user-input-box:nth-child(2n){
        justify-content: space-between;
    }

    .gender-category{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .main-user-info{
        max-height: 380px;
        overflow: auto;
    }

    .main-user-info::-webkit-scrollbar{
        width: 0;
    }

  .sex{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 250px;
    top: -20px;
  } 

  .sexx{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 200px;
    top: -20px;
  } 

  .ret2{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 310px;
    top: -20px;
  } 

  .sex2{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 100px;
    top: -20px;
  }
  .retra{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 250px;
    top: -20px;
  }
  .sex1{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 165px;
    top: -20px;
  }

  .ret1{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left:275px;
    top: -20px;
  }

  .sex11{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 170px;
    top: -20px;
  }
  .retr{
    position: relative;
    color: #e5e5e5;
    text-transform: capitalize;
    font-size: 24px;
    left: 220px;
    top: -20px;
  }
.serve{
    
    display:grid;
    text-align: center;
}

.obe{
    border-radius: 7px;
    color: black;
    text-align: center;
    outline: none;
    border: none;
    
}
.position{
    display: flex;
    justify-content: space-between;
}
.boite{

    max-width: 400px;
    min-width: 250px;
   border-radius: 7px;
   font-size: 18px;
}
.hh{
    color: rgb(25, 201, 233);
}
.tete{
    display: flex;
    justify-content: space-between;
}
.enr{
    width: 200px;
    height: 50px;
    background-color: green;
    color: white;
    font-size: 20px;
}
.favi{
    background-color:rgba(0, 128, 0, 0.485);
    
}
.enr:hover{
    background-color: rgb(13, 229, 42);
}
.enr:active{
    background-color: green;
}
.affOb{
    color: rgba(255, 217, 0, 0.58);
    display: flex;
}
.affOa{
    color: white;
    display: flex;
    justify-content:flex-end;
    
}

.lesrcoll{
    max-height: 380px;
    overflow: auto;
}

.lesrcoll::-webkit-scrollbar{
    width: 0;
}
.candName{
    color: white;
}