

.containerV{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    overflow: hidden;
}

.containerV:before{
    content: '';
    position: absolute;
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
    background: linear-gradient(-45deg, #4481eb, #04befe);
     
    top: -10px;
    right: 48%;
    transform: translateY(-50%);
    z-index: 6;
}

.forms-containerV{
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
}
.sign-in-form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0.5rem;
    overflow: hidden;
}

.titleV{
    font-size: 2.2rem;
    color: #444;
    margin-bottom: 10px;
}

.input-field{
    max-width: 380px;
    width: 100%;
    height: 55px;
    background-color: #f0f0f0;
    margin: 10px 0;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0.4rem;
    overflow: hidden;
}

.ic{
    text-align: center;
    line-height: 55px;
    color: #acacac;
    font-size: 1.1rem;
}

.input-field input{
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.input-field input::placeholder{
    color: #aaa;
    font-weight: 500;
}

.btn{
    width: 150px;
    height: 49px;
    border: none;
    outline: none;
    border-radius: 49px;
    cursor: pointer;
    background-color: #5989fd;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
    transition: .5s;
}

.btn:hover{
    background-color: #4d84e2;

}

.signin{
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 50%;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
}

.imoge{
    width: 80%;
}
.panels-containersV{
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.panel{
    display: flex;
    flex-direction: column;
    
    justify-content: space-around;
    text-align: center;
    z-index: 7;
}

.panel h3{
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}
.panel p{
font-size: 0.95rem;
padding: 0.7rem 0;

}
.panel content{
    color: #fff;
}

@media (max-width: 870px){
    .containerV{
        min-height: 800px;
        height: 100vh;

    }
    .containerV:before{
        width: 1500px;
        height: 1500px;
        left: 30%;
        bottom: 68%;
        transform: translate(-50%);
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
    }
    .signin{
        width: 100%;
        left: 50%;
        top: 85%;
        transform: translate(-50%, -100%);
    }
   .panels-containersV{
    
    z-index: 10;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
   }

   .panel{
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
   }

   .imoge{
    width: 200px;

   }
.left-panel{
    grid-row: 1/2;
}
   
.panel .contentV{
    padding-right: 15px;

}

}


@media (max-width: 570px){
    .sign-in-form{

        padding: 0 1.5rem;

    }

    .panels-containersV{
      
        top: -40px;
        left: 0;
      
    }
   
    .containerV{
        min-height: 740px;
        height: auto;

    }
   
   
    .panel .contentV{
        padding: 0.5rem 1rem;

    }

    .containerV:before{
        bottom: 72%;
        left: 50%;
    }
}


.toast {
 
    background-color: #ff6961; /* Couleur de fond du toast */
    color: #fff;
    padding: 5px;
    border-radius: 55px;
    display: flex;
    align-items: flex-start; /* Alignez les éléments en haut */
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 355px;
   
  }
  
  .toast-content {
    flex-grow: 1;
    margin-top: 5px; /* Ajustez la marge supérieure selon vos préférences */
    text-align: center;
  }
  
  .toast-close {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  
  
  