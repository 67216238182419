/*
.card-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: 250px;
    text-align: center;
}

.card img {
    width: 50%;
    height: 50%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.modifier-btn {
    background-color: #3498db;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modifier-btn:hover {
    background-color: #2980b9;
}
*/
/*
.card-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: calc(50% - 20px); 
    text-align: center;
}

.card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

h3, p {
    margin-bottom: 8px; 
}

.modifier-btn {
    background-color: #3498db;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modifier-btn:hover {
    background-color: #2980b9;
}
*/

.card-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}


.uncard-list{
  padding: 40px;
  margin-left: 15%;
  width: 70%;
}




.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
    padding: 20px;
    margin: 10px;
    width: calc(50% - 20px); /* 50% of the container width minus margin */
    text-align: center;
    display: flex;
    flex-direction: column; /* Les éléments enfants seront empilés verticalement */
    align-items: center; /* Centrer les éléments enfants horizontalement */
}

.card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

h3, p {
    margin-bottom: 1px;
}


.modifier-btn {
    background-color: #3498db;
    color: #fff;
    padding: 8px 16px;
    border: none;
    margin-bottom: 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.modifier-btn:hover {
    background-color: #2980b9;
}



.expense-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.expense-card {
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: calc(50% - 20px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


.expense-card-pop {
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
    padding: 20px;
    margin: 10px;
    width: calc(50% - 20px);
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    
}



h3, p {
    margin-bottom: 8px;
}

.edit-btn {
    background-color: #e44d26;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-btn:hover {
    background-color: #c13a1c;
}

.libV{
    font-weight: bold;
    font-size: 17px;
}
.mdT{
    font-size: 10px;
}

.info-btn {
    background-color: #4CAF50;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    min-width: 80px;
    margin-bottom: 8px;
}

.info-btn:hover {
    background-color: #45a049;
}

/* Popup Styles */
.popup-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}



.popup-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.modal-pop, .overlay-pop{
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay-pop{
    background: rgba(49, 49, 49, 0.063);
}

.modal-content-pop{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.look{
    display: flex;
    flex-direction: column;
    
 
}
.lookT{
    text-align: center;
    color: #fff;
}
.lookF{
    display: flex;
    justify-content: space-around;
}

.lookFF{
    display: flex;
    flex-direction: column;
    color: #fff;
}

.lookFF input{
    width: 100%;
    height: 40px;
}

.hai{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

    .authes{
        background-image: url(/src/css/fondx.jpg);
        min-height: 145vh;
        background-size: cover;
        justify-content: center;
        align-items: center;
        }


        @media all and (max-width: 860px){
            .authes{
                background-image: url(/src/css/vtest.jpg);
                min-height: 100vh;
                height: auto;
                background-size: cover;
                justify-content: center;
                align-items: center;
                }

                .modal2, .overlay2{
                    width: 100vw;
                    height: 100vh;
                    top:0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    position: fixed;
                }
            }

        

            @media all and (max-width: 380px){
                .authes{
                    background-image: url(/src/css/vtest.jpg);
                    min-height: 115vh;
                    height: auto;
                    background-size: cover;
                    justify-content: center;
                    align-items: center;
                    }
                }


                .barContener {
                    flex-direction: column; /* Afficher les éléments en colonne sur les petits écrans */
                    align-items: center; /* Centrer les éléments */
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                   
                    padding: 10px;
                    border-radius: 5px;
                }
               
                .rechBar,.choosee,.butonSF,.perSF,.seLe {
                    padding: 8px;
                    margin: 5px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                }

                .seLe{
                    width: 80%;
                }
            
                /* Styles spécifiques pour le bouton "Appliquer" */
                .butonSF {
                background-color: #4CAF50;
                color: white;
                cursor: pointer;
            }
            
            /* Styles pour les options du select */
            select {
                width: 150px; /* Ajuster la largeur selon vos besoins */
            }
            
            .persBarT p {
                margin-right: 10px; /* Ajuster la marge à droite selon vos besoins */
                
            }


            @media screen and (max-width: 600px) {
                .modal-content2 {
                    padding: 10px;
                    max-width: 80%;
                    min-width: 300px;
                }
            }
            
            @media screen and (max-width: 400px) {
                .modal-content2 {
                    padding: 8px;
                    max-width: 90%;
                    min-width: 250px;
                }
            }
              
            .naI{
                margin-top: 10px;
               color: white;
               font-weight: bold;
               font-size: 17px;
           }

           .checko{
            width: 50%;
            height: 40%;
        }

        .modaFF{
            font-size: 30px;
            color: red;
        }

        .butonSF3 {
          background-color: #4CAF50;
          color: white;
          cursor: pointer;
          width: 110px;
        }


        .butonSF4 {
          height: 38px;
          background-color: #4CAF50;
          color: white;
          cursor: pointer;
          width: 120px;
        }

        .butonSF5 {
          height: 38px;
          background-color: #4CAF50;
          color: white;
          cursor: pointer;
          width: 150px;
        }

        .butonSF6 {
          background-color: #4CAF50;
          color: white;
          cursor: pointer;
          width: 90px;
        }

        .butonSF7 {
          background-color: #4CAF50;
          color: white;
          cursor: pointer;
          width: 90px;
          height: 25px;
        }

        .masthead {
            background-image: url('/src/css/header-bg.jpg');
            background-size: cover;
            background-position: center;
            color: #fff;
            text-align: center;
            padding: 10rem 0;
            background-repeat: no-repeat;
          }
         
          header.masthead .masthead-subheading {
            font-size: 1.5rem;
            font-style: italic;
            line-height: 1.5rem;
            margin-bottom: 25px;
            font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          }
        
        
          header.masthead .masthead-heading {
            font-size: 3.25rem;
            font-weight: 700;
            line-height: 3.25rem;
            margin-bottom: 2rem;
            font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          }
        
          .masthead-brand {
            position: absolute;
            top: 60px;
            left: 15px;
            color: gold;
            padding: 1rem;
            font-size: 1.5rem;
            
          }
          
          .btn-xl {
            font-size: 1.25rem;
            padding: 1rem 2rem;
          }
        
        
        
        
          .navT {
            background-color: #000;
            padding: 1rem 0;
          }
          
          .navbar-brand {
            color: #fff;
            font-size: 1.5rem;
            text-decoration: none;
          }
          
          .navbar-toggleT {
            background-color: transparent;
            border: none;
            color: #fff;
            font-size: 1.25rem;
          }
          
          .navbar-toggleT:focus {
            outline: none;
          }
          
          #navbarResponsive {
            display: none;
          }
          
          .navbar-nav {
            margin-left: auto;
          }
          
          .nav-link {
            color: #fff;
            text-decoration: none;
            margin-left: 2rem;
          }
          
          .page-section {
            padding: 50px 0;
          }
          
          .section-heading {
           
            color: #000;
          }
        
          .page-section h2.section-heading, .page-section .section-heading.h2 {
            font-size: 2.5rem;
            margin-top: 0;
            margin-bottom: 1rem;
          }
          
          .fefe{
            display: flex; /* Utilisation de flexbox pour aligner les cartes horizontalement */
          justify-content: space-between;
          }
          .carte{
            width: calc(33.33% - 20px); /* 33.33% de la largeur du conteneur moins un espace entre les cartes */
          margin-right: 20px; /* Marge à droite de chaque carte, ajustable selon vos préférences */
          background-color: #f0f0f0; /* Couleur de fond des cartes */
          padding: 20px; /* Espacement interne des cartes */
          box-sizing: border-box;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .circle{
            width: 100px; /* Ajustez la taille du cercle selon vos besoins */
            height:100px; /* Ajustez la taille du cercle selon vos besoins */
            background-color: #007bff; /* Couleur de fond du cercle */
            border-radius: 50%; /* Pour rendre le div un cercle */
            align-items: center;
            
          }
          .section-subheading {
            font-size: 1.5rem;
            font-weight: 400;
            color: #888;
          }
          
          .service-item {
            margin-bottom: 30px;
          }
          .text-center{
            text-align: center;
          }
          
        
          .service-item {
            text-align: center;
          }
          
          .service-icon {
            
            font-size: 4rem;
            color: white;
            
            color: #007bff;
          }
        
          .row {
            display: flex;
            justify-content: center;
          }
        
          @media (max-width: 768px) {
            .row {
              flex-direction: column;
              align-items: center;
            }
          
            .col {
              width: 100%;
            }
        
            .fefe {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              }
              
              .carte {
                
                flex: 1;
                margin: 10px;
                flex-basis: 100%;
              }
          }
          .my-3{
            margin-top: 1rem ;
          margin-bottom: 1rem;
          font-size: 25px;
          }
        
          .page-section h3.section-subheading, .page-section .section-subheading.h3 {
            font-size: 1rem;
            font-weight: 400;
            font-style: italic;
            font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            margin-bottom: 4rem;
          }
        
          .text-muted {
            --bs-text-opacity: 1;
            color: #6c757d ;
            font-size: 15px;
          }

          
/* Pour les écrans de taille moyenne (tablettes) */
@media only screen and (min-width: 600px) {
  .card {
      width: calc(25% - 20px); /* 25% of the container width minus margin */
  }
}

/* Pour les écrans de grande taille (ordinateurs) */
@media only screen and (min-width: 1024px) {
  .card {
      width: calc(16.666% - 20px); /* 16.666% of the container width minus margin (pour afficher 6 cartes par ligne) */
  }
}


  /* Pour les écrans de taille moyenne (tablettes) */
  @media only screen and (max-width: 1023px) {
    .bilan-depense {
        flex-basis: calc(50% - 20px); /* 50% of the container width minus margin */
    }
  }
  
  /* Pour les écrans de grande taille (ordinateurs) */
  @media only screen and (min-width: 1024px) {
    .bilan-depense {
        flex-basis: calc(32% - 5px); /* 16.666% of the container width minus margin (pour afficher 6 éléments par ligne) */
    }
  }


  

.bilan-container {
  display: flex;
  justify-content: space-between; /* Les éléments seront espacés également */
}


.recettes-section {
  flex: 1; /* La section des recettes prendra autant d'espace que possible */
  margin-left: 150px;
  padding: 20px;
}

.depenses-section {
  flex: 1; /* La section des dépenses prendra autant d'espace que possible */
margin-right: 150px;
  padding: 20px;
}



@media only screen and (max-width: 1023px) {
  .depenses-section {
    flex: 1; /* La section des dépenses prendra autant d'espace que possible */
  margin-right: 0px;
    padding: 20px;
  }

  .recettes-section {
    flex: 1; /* La section des recettes prendra autant d'espace que possible */
    margin-left: 0px;
    padding: 20px;
  }
  
}



.fil{
	display: flex;
    justify-content: center;
    margin: 30px;

}

.disEnd{

  display: flex;
  justify-content: space-between;
  width: 50%;

}


.butonSF3D {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  width: 110px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
  border: none;
}

.oixi{
  height: 28px;
  width: 200px;
  border-radius: 7px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
}



@media only screen and (max-width: 760px) {
  .fil{
      display: flex;
      justify-content: start;
      margin: 30px;
  
  }

  .disEnd{

      display: flex;
      justify-content: space-between;
      width: 100%;
   
   }

   .oixi{
      height: 28px;
      width: 110px;
      border-radius: 7px;
      border: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
  }

  .ban2{

    display: flex;
    justify-content: space-around;
    
    width: 100%; /* Largeur de la bannière à 100% de la largeur de la page */
   height: 200px; /* Hauteur de la bannière */
   background-image: url('/src/css/ban3.jpg'); /* Chemin vers votre image */
   background-size: cover; /* Redimensionner l'image pour qu'elle couvre la totalité de la bannière */
   background-position: center; /* Centrer l'image */
   text-align: center; /* Aligner le texte au centre si vous en ajoutez */
   color: white; /* Couleur du texte */
  align-items: center;
  
  
  
   font-size: 1.60rem;
     font-weight: 700;
     line-height: 1.60rem;
     margin-bottom: 2rem;
     font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   
  }

  
.arrow-icon {
  color: rgb(6, 122, 116); /* Couleur bleue */
  font-size: 1.65rem; /* Taille de la police de l'icône */
}

.arrow-icon2 {
  color: rgba(27, 27, 28, 0.242); /* Couleur bleue */
  font-size: 1.65rem; /* Taille de la police de l'icône */
}
  
}



.llo{
	color: transparent;
	-webkit-text-stroke: 1px white;
    background: url(/src/css/pattern.png);
	-webkit-background-clip: text;
	background-clip: text;
	background-position: 0 0;
	animation: animate 20s linear 2s infinite alternate;
    top: -150px;
}








.modal-contentSupInfo{
  text-align:center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: transparent;
    color: white;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.containerModal{
    width: 100%;  
    background: rgba(0, 0, 0, 0.5);
    padding: 28px;
    
    border-radius: 10px;
    box-shadow: inset -2px 2px 2px white;
}


.form-title2{
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 6px;
    color: white;
    text-shadow: 2px 2px 2px black;
    border-bottom: solid 1px white;
}



.labelCmot{
    width: 95%;
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin: 5px 0;
}

.inputCmot{
    height: 40px;
    width: 80%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    padding: 0 10px;
}

.inputCmot1{
    height: 40px;
    width: 30%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    color: grey;
    padding: 0 10px;
    pointer-events: none;
    cursor: default;
}



/* .lep:hover .options {
    display: grid;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 5px;
  }
  
  .lep:hover .user,
  .lep:hover .hero {
    opacity: 0.3;
  } */


.options {
  
  display: none;
  position: absolute;
  top: 160%;
  left: 10%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.option-btn {
  
  margin: 5px 0;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
}

.option-btn:hover {
  background-color: #0056b3;
}

.option-btn2 {
  
  margin: 5px 0;
  padding: 8px 16px;
  background-color: #de2525;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
}

.option-btn2:hover {
  background-color: #fb0505;
}








.nm1{
  width: 100px;
  height: 30px;
  background-color: green;
  color: white;
}
.nm2{
  width: 100px;
  height: 30px;
  background-color: rgb(166, 20, 20);
  color: white;
}



.dropdown-menu {
  position: absolute;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  
  left: 20%;
  z-index: 1000;
}

.option-btnMobi, .option-btnMobi2 {
  display: block;
  color: white;
  background-color: #444;
  padding: 8px 10px;
  border: none;
  border-radius: 3px;
  margin: 5px 0;
  cursor: pointer;
  width: 100%;
}

.option-btn:hover, .option-btnMobi2:hover {
  background-color: #555;
}



.fenetre-modal {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  max-height: 70vh; /* Limite la hauteur de la fenêtre à 70% de la hauteur de la vue */
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.modal-entete {
  text-align: center;
  margin-bottom: 20px;
}

.titre-modal {
  font-size: 1.5rem;
  color: #333;
}

.modal-corps {
  flex: 1;
  overflow-y: auto; /* Ajoute une barre de défilement verticale si le contenu dépasse */
}

.table-inscrits {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table-inscrits th {
  background-color: #87CEEB;
  color: white;
  padding: 10px;
  text-align: left;
  font-weight: bold;
}

.table-inscrits td {
  padding: 10px;
  text-align: left;
}

.ligne-grise {
  background-color: #f0f0f0;
}

.ligne-blanche {
  background-color: #fff;
}

.boutons-modal {
  text-align: center;
  margin-top: 10px;
}

.bouton-annuler {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.bouton-annuler:hover {
  background-color: #c82333;
}



@media (max-width: 600px) {
  .table-inscrits th, .table-inscrits td {
    display: block;
    width: 100%;
    text-align: left;
  }

  .table-inscrits td::before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }

  .table-inscrits tr {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .table-inscrits td {
    padding: 5px 10px;
    flex: 1;
  }

  .table-inscrits th {
    display: none;
  }
}



.bnfs{
  text-decoration: none; /* Supprime le soulignement du lien */
  color: inherit; /* Hérite de la couleur du texte du parent */
}

.modifier-btn3 {
  background-color: #12e779;
  color: #fff;
  padding: 8px 16px;
  border: none;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.modifier-btn3:hover {
  background-color: #04f859;
}

.eye-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  margin-left: 8px;
  vertical-align: middle;
}

.login-details {
  margin-top: 5px;
  color: #333;
}

.modal-content3{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: grey;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 200px;

  color: white;
}


.btn-create{
  width: 90px;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 49px;
  cursor: pointer;
  background-color: #5989fd;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: .5s;
}


.image-container8 {
  position: relative;
}

.camera-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}


.bloom{
  margin-top: 20px;
}



.contact-link {
  color: #007bff;
  text-decoration: none;
  
  align-items: center;
  font-weight: bold; /* Mettre en évidence le numéro */
  font-size: 15px; /* Taille légèrement plus grande que le texte standard */
}

.phone-icon {
  margin-right: 1px;
  color: #007bff; /* Même couleur verte que le lien pour uniformité */
  font-size: 14px; /* Taille légèrement plus grande que le texte */
}

.contact-link:hover {
  color: #0056b3; /* Couleur verte plus foncée au survol */
}

.contact-link:hover .phone-icon {
  color: #0056b3; /* Changer la couleur de l'icône au survol aussi */
}

.card-list20 {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: flex-start;
}

.card20 {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
  padding: 20px;
  margin: 10px;
  width: calc(50% - 20px); /* 50% of the container width minus margin */
  text-align: center;
  display: flex;
  flex-direction: column; /* Les éléments enfants seront empilés verticalement */
  align-items: center; /* Centrer les éléments enfants horizontalement */
  min-height: 250px; /* Hauteur minimale pour uniformiser les cartes */
  transition: all 0.3s ease;
}

.card20 img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 600px) {
  .card20 {
      width: calc(25% - 20px); /* 25% of the container width minus margin */
  }
}

/* Pour les écrans de grande taille (ordinateurs) */
@media only screen and (min-width: 1024px) {
  .card20 {
      width: calc(16.666% - 20px); /* 16.666% of the container width minus margin (pour afficher 6 cartes par ligne) */
  }
}


.card-expanded {
  min-height: auto; /* Permet à la carte de s'étendre en hauteur */
  height: auto; /* Hauteur automatique pour s'adapter au contenu */
}


.persBarT {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Répartit l'espace entre les éléments */
  flex-wrap: nowrap; /* Empêche les éléments de passer à la ligne suivante */
  gap: 10px; /* Espace entre les éléments */
  overflow: hidden; /* Cache tout débordement */

}

.persBarT .perSF, .persBarT .butonSF {
  flex: 1 1 auto; /* Permet aux éléments de s'ajuster à l'espace disponible */
  min-width: 0; /* Assure que les éléments peuvent rétrécir si nécessaire */
  box-sizing: border-box; /* Inclut les bordures et le padding dans la largeur totale */

}

.persBarT .butonSF {
  flex-shrink: 0; /* Empêche le bouton de rétrécir trop */
}

/* Media Query pour les petits écrans */
@media (max-width: 400px) {
  .persBarT {
    overflow-x: auto; /* Ajoute une barre de défilement horizontale si nécessaire */
 width: 95%;
  }

  .persBarT .perSF, .persBarT .butonSF {
    flex: 1 1 0; /* Les éléments s'ajustent pour utiliser tout l'espace disponible */
    min-width: 0; /* Permet aux éléments de rétrécir si nécessaire */
    box-sizing: border-box; /* Inclut les bordures et le padding dans la largeur totale */
  }
}

.nav-text.active {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  
  background-color: #4CAF50; /* Modifiez cette couleur selon vos préférences */
  color: #fff;
}

.lookTZ{
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
    font-weight: 400;
   
}

@media(max-width: 600px){
  
  
.lookTZ{
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 300;
  
}
}


.lookFZ{
 
  
  text-align: center;
 
}

.lookFZ select{
  height: 40px;
  width: 80%;
  border-radius: 7px;
  outline: none;
  border: 1px solid grey;
  padding: 0 10px;
}

.tool{
  color: rgb(7, 217, 206);
  margin-top: 10px;
}

.btnP{
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 49px;
  cursor: pointer;
  background-color: #5989fd;
  color: #fff;
  text-transform: uppercase;
margin-left: 30px;
  font-weight: 600;

  transition: .5s;
}

.btnP:hover{
  background-color: #4d84e2;

}

/*

*/




.profile-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.upload-options {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.import-btn, .camera-btn {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.import-btn:hover, .camera-btn:hover {
  background-color: #0056b3;
}

.camera-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 2px solid #ccc;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 500px) {
  .import-btn, .camera-btn {
    width: 40%;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
}

.capture-btn {
  margin-top: 20px;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.capture-btn:hover {
  background-color: #218838;
}


.cancel-btn{
  margin-top: 20px;
  padding: 10px;
  background-color: #282aa7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}




.camera-modal-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bouton-camera-capture, 
.bouton-camera-confirmer, 
.bouton-camera-reprendre {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  margin: 10px;
}

.bouton-camera-capture {
  display: block;

}

.bouton-camera-confirmer i {
  color: green;
}

.bouton-camera-reprendre i {
  color: orange;
}

.bouton-camera-annuler {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: red;
  margin-top: 5px;
}

.bouton-camera-annuler:hover {
  color: #FF6347;
}

video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px auto;
}

.image-container8 {
  position: relative;

}

.camera-icon60 {
  position: absolute;
  bottom: 10px;
  left: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}






.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(66, 64, 64, 0.015);;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(66, 64, 64, 0.015);
  width: 300px;
  text-align: center;
}

.popup-buttons {
  margin-top: 20px;
}

.popup-buttons button {
  width: 34%;
  margin: 0 5px;
  border: none;
  border-radius: 10px 10px;
  padding: 3px;
}


.continuer{
  text-align: center;
}


.pop-oui{
  background-color: rgba(10, 159, 10, 0.733);
 
  color: white;
}

.pop-non{
  background-color: rgba(255, 0, 0, 0.602);
  color: white;
}

.pop-ok{
  background-color: #3498db;
  color: white;
}






.input-field10 .toggle-password {
  position: absolute; /* Positionnement absolu pour l'icône */
  right: 10px; /* Espace à droite */
  cursor: pointer; /* Changer le curseur pour indiquer que c'est cliquable */
  color: #acacac; /* Couleur de l'icône */
}

.ic10 {
  text-align: center;
    line-height: 55px;
    color: #acacac;
    font-size: 1.1rem;
    margin-right: 20px; /* Espace entre l'icône et le champ de saisie */
    margin-left: 20px; /* Ajout d'espace à gauche de l'icône */
}


.input-field10 {
  position: relative; 
  max-width: 380px;
  width: 100%;
  height: 55px;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 55px;
  display: flex; 
  align-items: center; 
  padding: 0.4rem;
  overflow: hidden;
}

.input-field10 input {
  flex: 1; 
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  /* font-weight: 600; */
  font-size: 1.1rem;
  color: #333;
  padding-right: 40px; /* Espace à droite pour l'icône de visibilité du mot de passe */
  padding-left: 10px; /* Ajout d'espace à gauche pour le texte */
}



.secretModalSup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1002; /* Assurez-vous que la modale est au-dessus des autres éléments */
}

.secretOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond semi-transparente */
}

.secretModalContent {
  position: relative;
  background-color: white; /* Fond blanc pour le contenu de la modale */
  border-radius: 8px;
  padding: 20px; /* Ajout de l'espace intérieur */
  width: 80%; /* Largeur de la modale */
  max-width: 600px; /* Largeur maximum */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Ombre pour donner de la profondeur */
}

.secretContainerModal {
  display: flex;
  flex-direction: column;
}

.secretFormTitle {
  text-align: center;
  margin-bottom: 20px; /* Espace en dessous du titre */
}

.secretInputCmot {
  width: 100%;
  height: 40px; /* Hauteur du champ de saisie */
  margin-bottom: 6px; /* Espace en dessous de chaque champ */
  border-radius: 5px;
  border: 1px solid #ccc; /* Bordure légère */
  padding: 0 10px; /* Espace intérieur */
  font-size: 1rem; /* Taille de la police */
  outline: none;
}

.secretSupD {
  display: flex;
  justify-content: space-between; /* Espacement entre les champs */

}

.secretNm1, .secretNm2 {
  background-color: #007bff; /* Couleur de fond des boutons */
  color: white; /* Couleur du texte */
  border: none; /* Pas de bordure */
  border-radius: 5px; /* Coins arrondis */
  padding: 10px 15px; /* Espacement intérieur */
  cursor: pointer; /* Curseur pointeur */
}

.secretNm2 {
  background-color: #dc3545; /* Couleur de fond pour le bouton Annuler */
}
/* Responsive */
@media (max-width: 600px) {
  .secretModalContent {
    width: 90%; /* Ajustement de la largeur de la modale sur petit écran */
    padding: 15px; /* Moins de rembourrage */
  }

  .secretInputCmot {
    height: 35px; /* Ajustement de la hauteur des champs sur petit écran */
  }

  .secretSupD {
    flex-direction: row; /* Aligne les champs sur la même ligne */
    align-items: center; /* Centrer verticalement les éléments */
  }

  .secretSupD input {
    flex: 1; /* Permet aux champs de prendre la même largeur */
    margin-right: 10px; /* Espace entre les champs */
  }

  .secretSupD input:last-child {
    margin-right: 0; /* Aucun espace à droite pour le dernier champ */
  }

  /* Style des boutons dans le footer de la modale */
  .secretSupD {
    justify-content: space-between; /* Espacement entre les boutons */
    margin-top: 20px; /* Espacement au-dessus des boutons */
  }

  .secretNm1, .secretNm2 {
    width: 100%; /* Largeur des boutons pour qu'ils s'adaptent côte à côte */
  }
}



.prsp20 {
  margin: 10px;
  padding: 7px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.prsp20:first-of-type{
  background-color: rgb(0, 42, 255);
  color: white;
}

.prsp20:last-of-type {
  background-color: rgb(0, 51, 255);
  color: white;
}


.prsp {
  margin: 10px;
   padding: 7px 19px;
  
   border: none;
   border-radius: 4px;
   cursor: pointer;
 }
 
 .prsp:first-of-type{
   background-color: red;
   color: white;
 }
 
 .prsp:last-of-type {
   background-color: red;
   color: white;
 }




 
 
.dropeModal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dropeModal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.dropeModal-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px; /* Ajoute un espacement vertical entre les boutons */
}

.dropeModal-button-confirm {
  background-color: #4CAF50; /* Vert pour confirmer */
  color: white;
}


.dropeModal-button-cancel {
  background-color: #f44336; /* Rouge pour annuler */
  color: white;
}



.dropeModal-button-info {
  background-color: #2196F3; /* Bleu pour l'information */
  color: white;
}















@media all and (min-width: 861px) and (max-width: 1130px) {

  .cod {
      margin-left: -500px;
      margin-top: 400px;
      width: 380px;
      padding: 90px;
  }

}



.image-profilUser{
  
  width: 34px;
  height: 34px;
  border-radius: 50%; /* Cela rend l'image ronde, comme une icône */
  object-fit: cover; /* Pour s'assurer que l'image s'adapte bien dans le conteneur */
  margin-right: 10px;

}


.option-btn10 {
  
  margin: 5px 0;
  padding: 8px 16px;
  background-color: #39f43fd8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
}

.option-btn10:hover {
  background-color: #0a7915;
}



.arrow-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden; /* Empêche les flèches de dépasser */
}

.arrow {
  position: relative;
  padding: 10px 8px; /* Moins de padding vertical pour éviter les flèches trop hautes */
  color: white;
  text-align: center;
  font-weight: bold;
  flex-grow: 1; /* Assure que toutes les flèches prennent la même largeur */
  margin-right: -5px; /* Espace entre les flèches */
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%);
  display: flex;
  justify-content: center;
  align-items: center; /* Centre le texte verticalement */
  min-height: 50px; /* Hauteur uniforme pour toutes les flèches */
  font-size: 12px;
  transition: background-color 0.3s; /* Transition douce pour le changement de couleur */
}

/* Couleur standard pour les flèches activées (ex: couleur de cour) */
.arrow.active {
  background-color: #5bc0de; /* Remplace par la couleur de cour souhaitée */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Ajoute une ombre aux flèches activées */
}

/* Couleur grise pour les flèches désactivées */
.arrow:not(.active) {
  background-color: #ccc; /* Couleur grise pour les flèches désactivées */
}

.arrow:last-child {
  margin-right: 0; /* Supprime la marge après la dernière flèche */
}

.dave{
  font-display: flex;
  justify-content: space-around;
 
  margin-top: 20px;
}

.bande-defilante {

  color: white;
 
  width: auto;
  box-sizing: border-box;
  text-align: center;
  top: 55px;
  left: 0;
  font-size: 20px;
 
  z-index: 10; /* Assure que la bande est par-dessus les autres éléments */
}



.bless{
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



.success-liste-candidats {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre la liste */
  padding: 20px;
}

.success-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px; /* Limite la largeur des cartes */
  margin: 10px 0; /* Espacement entre les cartes */
  padding: 15px;
}

.success-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.success-info h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.success-icons {
  display: flex;
  gap: 10px;
}

.success-icon {
  color: #25D366; /* Couleur WhatsApp */
  font-size: 1.5em;
}

.success-icon:hover {
  color: #128C7E; /* Couleur au survol */
}



.tooltip {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
}


/* Styles de base du Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(13px);
}


.message-erreur {
  color: #ff4c4c; /* Rouge clair pour attirer l'attention */
  background-color: #ffecec; /* Fond léger pour rester lisible */
  border: 1px solid #ff4c4c; /* Bordure assortie pour encadrer */
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  margin: 20px auto;
  max-width: 80%;
}




/* Styles de base du Toggle Switch */
.wolf-toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.wolf-toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.wolf-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.wolf-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Quand activé (checked), la couleur change selon Admis ou Non Admis */
.wolf-toggle-input:checked + .wolf-slider.wolf-admis {
  background-color: rgb(14, 217, 14);
}

.wolf-toggle-input:checked + .wolf-slider.wolf-admis:before {
  transform: translateX(13px);
}

.wolf-toggle-input:checked + .wolf-slider.wolf-non-admis {
  background-color: red;
}

.wolf-toggle-input:checked + .wolf-slider.wolf-non-admis:before {
  transform: translateX(13px);
}

/* Action Bar styles */
.wolf-action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  border-top: 2px solid #ddd;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5000;
}

.wolf-toggles {
  display: flex;
  gap: 20px;
}

.wolf-toggle-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wolf-loader {
  margin-left: 20px;
  font-weight: bold;
 position: fixed;

}

.wolfOui{
  color: #036d28;
  font-weight: bold;
  border: 1px solid #047f2f;
}

.wolfNon{
  color: #dc3545;
  font-weight: bold;
  border: 1px solid #dc3545;
}


.wolf-checkbox {
  transform: scale(1.4);
  margin-bottom: 10px;
  margin-left: 90%;
  cursor: pointer;
}



.alertMM{
  margin-top: 10px;
}










/* FullCalendar Container Style */
.calendar-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden; /* Limite le débordement */
}

/* Responsiveness */
@media (max-width: 768px) {
  .calendar-container {
    padding: 10px;
    overflow-x: auto; /* Ajoute un défilement horizontal si besoin */
  }
}

.fc-daygrid-event {
  background-color: #007bff !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 4px !important;
}

.fc-toolbar-title {
  color: #333333;
  font-size: 1.5em;
}


/* Styles pour le conteneur du calendrier */
.calendar-container {
  width: 100%;
  max-width: 900px;
  margin: 30px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Vue en liste pour mobile */
.presence-list {
  padding: 10px;
  background-color: white;
}

.presence-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.presence-item b {
  color: #007bff;
}

@media (max-width: 768px) {
  .presence-item {
    font-size: 0.9em;
  }
}



.agendeCV{
  margin-top: 30px;
}

.downI i {
  font-size: 20px;
  color: #2196F3;
}






/* Style de base pour la page employé */
.employ-wini {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.employ-profils-container {
  padding: 20px;
}

/* .employ-h2 {
  font-size: 1.5em;
  color: #06497d;
  margin-bottom: 10px;
  font-weight: bold;
} */

.employ-h2-container {
  background-color: #0c808d; /* Couleur de fond */
  padding: 10px 15px;
  text-align: left; /* Alignement par défaut */
  color: #ffffff; /* Couleur du texte */
  border-radius: 8px;
  margin-top: 20px;
}

.employ-h2 {
  font-size: 1.5em;
  color: #ffffff;
  margin: 0; /* Enlever le margin pour mieux centrer le texte dans le conteneur */
  font-weight: bold;
}

.employ-modal-note {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
  padding: 10px;
}


/* Centrage sur les petits écrans */
@media (max-width: 768px) {
  .employ-h2-container {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .employ-h2-container {
    text-align: left; /* Alignement à gauche pour PC */
    display: inline-block; /* Adapte la largeur au texte */
  }
}

.employ-profils-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.employ-profil-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.employ-profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.employ-lab {
  font-size: 0.9em;
  color: #555;
  margin: 10px 0;
}

/* Toggle Switch */
.toggle-switch {
  position: relative;
  width: 40px;
  height: 20px;
  margin-top: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
  left: 3px;
  bottom: 3px;
}

.toggle-switch input:checked + .slider {
  background-color: #06497d;
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(18px);
}

/* Bouton de gestion des autorisations */
.employ-action-button {
  background-color: #06497d;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
}

.employ-action-button:hover {
  background-color: #05375b;
}

/* Styles pour la fenêtre modale */
.employ-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.employ-modal-content {
  background-color: #ffffff;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.employ-modal-title {
  font-size: 1.2em;
  color: #0c808d;
  margin-bottom: 20px;
  font-weight: bold;
}

.employ-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

.employ-modal-close-button:hover {
  color: #06497d;
}

.employ-modal-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.employ-modal-option-label {
  font-size: 1em;
  color: #333;
}

.employ-modal-toggle {
  position: relative;
  width: 40px;
  height: 20px;
}

.employ-modal-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.employ-modal-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.employ-modal-toggle .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
  left: 3px;
  bottom: 3px;
}

.employ-modal-toggle input:checked + .slider {
  background-color: #06497d;
}

.employ-modal-toggle input:checked + .slider:before {
  transform: translateX(6px);
}


/* Styles pour les écrans plus petits (ex. smartphones) */
@media (max-width: 768px) {
  .employ-wini {
    padding: 10px;
  }

  .employ-profils-container {
    padding: 10px;
  }

  .employ-h2 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .employ-profils-grid {
    grid-template-columns: 1fr; /* Une seule colonne */
    gap: 10px;
  }

  .employ-profil-card {
    padding: 15px;
  }

  .employ-profile-image {
    width: 60px;
    height: 60px;
  }

  .employ-lab {
    font-size: 0.8em;
  }

  .toggle-switch {
    width: 30px; /* Réduire la taille du switch */
    height: 15px;
  }

  .employ-action-button {
    padding: 6px 10px;
    font-size: 0.8em;
  }

  .employ-modal-content {
    width: 90%; /* Ajuster la largeur de la modal */
  }

  .employ-modal-title {
    font-size: 1.1em;
  }

  .employ-modal-option-label {
    font-size: 0.9em;
  }
}

/* Styles pour les écrans encore plus petits (ex. très petits smartphones) */
@media (max-width: 480px) {
  .employ-h2 {
    font-size: 1.1em;
  }

  .employ-profils-grid {
    gap: 5px; /* Réduire l'espace entre les cartes */
  }

  .employ-profil-card {
    padding: 10px; /* Réduire le padding */
  }

  .employ-profile-image {
    width: 50px; /* Réduire la taille de l'image du profil */
    height: 50px;
  }

  .employ-lab {
    font-size: 0.7em;
  }

  .toggle-switch {
    width: 25px; /* Réduire la taille du switch */
    height: 12px;
  }

  .employ-action-button {
    padding: 5px 8px;
    font-size: 0.7em;
  }

  .employ-modal-content {
    width: 95%; /* Ajuster la largeur de la modal pour les très petits écrans */
  }

  .employ-modal-title {
    font-size: 1em;
  }
}





.forTitle{
  margin: 15px;
}

@media (min-width: 768px) {
  .forTitle{
    margin-left: 70px;
  }
}



.depfixe-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.depfixe-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
}

.depfixe-modal-content h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.depfixe-modal-confirm {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.depfixe-modal-cancel {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.depfixe-modal-confirm:hover {
  background-color: #c9302c;
}

.depfixe-modal-cancel:hover {
  background-color: #5a6268;
}




.depfixe-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  margin: 20px auto;
}

.depfixe-titre {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Style pour la sélection de la ville */
.depfixe-selection-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.depfixe-select {
  width: 60%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.depfixe-btn-appliquer {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.depfixe-btn-appliquer:hover {
  background-color: #0056b3;
}

.depfixe-form label {
  margin-top: 10px;
  display: block;
  font-weight: bold;
}

#depfixe-nom,
#depfixe-montant,
#depfixe-frequence,
#depfixe-start-date,
#depfixe-date {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}


.depfixe-supprimer {
  background-color: #d9534f; /* Rouge pour signaler un danger */
  color: white;
  border: none;
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.depfixe-supprimer:hover {
  background-color: #c9302c;
}


.depfixe-bouton {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.depfixe-bouton:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .depfixe-form {
      padding: 15px;
  }
  .depfixe-selection-container {
      flex-direction: column;
      align-items: flex-start;
  }
}




@media (min-width: 768px) {
  .ocdfg {
    max-width: 80%;
    text-align: left; /* Alignement à gauche pour PC */
    display: inline-block; /* Adapte la largeur au texte */
    margin-left: 50px;
    margin: 30px;
  }
}

.ocdfg {
  background-color: transparent;
 
 
  padding: 20px;
 
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ocdfg-ti {
  background-color: #0c808d;
  margin-bottom: 20px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 25px;
}

.forTitle{
  margin: 15px;
}


@media (min-width: 768px) {
  .forTitle{
    margin-left: 70px;
  }

  

}

/*.ocdfg-list-itemM {
   background-color: white;
  margin: 10px 0;
  padding: 17px;
  border: 3px solid transparent;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  animation: moveBorder 4s infinite linear;
} */

.ocdfg-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ocdfg-list-item {
  background-color: white;
  margin: 10px 0;
  padding: 5px;
  border: 3px solid transparent;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  animation: moveBorder 4s infinite linear;
}

.ocdfg-list-item h3 {
  margin: 0;
  font-size: 16px;
}

.ocdfg-details {
  margin-top: 10px;
  font-size: 14px;
}


.icone-modifie3 {
  position: relative; /* Positionnement relatif pour l'icône */
}

.icone-modifie3::before {
  content: "\270E"; /* Code du caractère de crayon */
  position: absolute;
  top: 250%;
  right: -12px;
  color: rgb(35, 161, 234);
  transform: translateY(-50%);
  font-size: 30px;
}


.icone-modifie4 {
  position: relative; /* Positionnement relatif pour l'icône */
}

/* .icone-modifie4::before {
  content: "\270E"; 
  position: absolute;
  top: 20px;
  right: -20px;
  color: rgb(35, 161, 234);
  transform: translateY(-50%);
  font-size: 25px;
} */




.ocdfg-list-itemM {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Positionne l'icône à l'extrémité droite */
  background-color: white;
  margin: 10px 0;
  padding: 17px;
  border: 3px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  animation: moveBorder 4s infinite linear;
}

.texte-depenseM {
  flex: 1; /* Permet à cette section de prendre tout l'espace restant */
  margin-right: 10px; /* Ajoute un peu d'espace entre le texte et l'icône */
}

.icone-modifie4::before {
  content: "\270E"; /* Icône de crayon */
  color: rgb(35, 161, 234);
  font-size: 25px;
}


.fixe{
  height: 50px;
  width: 100px;
  background-color: green;
  color: white;
  border: none;
  font-size: 12px;
}










.employ-action-buttonPrint {
  background-color: #06497d;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
   margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-left: 65px;
}

/* Style au survol */
.employ-action-buttonPrint:hover {
  background-color: #05375b;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styles pour les petits écrans */
@media (max-width: 600px) {
  .employ-action-buttonPrint {
    width: 90%; /* Occupe une grande partie de la largeur sur petit écran */
    margin: 0 auto; /* Centre le bouton horizontalement */
    margin-top: 12px;
  }
}

/* Styles pour les écrans moyens et grands */
@media (min-width: 601px) {
  .employ-action-buttonPrint {
    width: auto; /* Taille ajustée */
    padding: 10px 15px; /* Légèrement plus grand sur grand écran */
    margin-top: 12px;
  }
}











/* Conteneur principal improbable */
.conteneur-menu-improbable {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Icône de profil improbable */
.icone-profil-improbable {
  font-size: 2rem;
  color: #4a90e2;
  transition: transform 0.2s ease-in-out;
}

.icone-profil-improbable:hover {
  transform: scale(1.1);
}

/* Menu déroulant improbable */
.menu-deroulant-improbable {
  position: absolute;
  top: 70px;
  left: 55px;
  width: 290px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10;
  animation: apparition-improbable 0.3s ease-in-out;
}

/* Animation d'apparition */
@keyframes apparition-improbable {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Élément du menu improbable */
.element-menu-improbable {
  padding: 12px 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
}

.element-menu-improbable:hover {
  background-color: #f5f5f5;
}

/* Bouton de déconnexion improbable */
.deconnexion-improbable button {
  width: 100%;
  padding: 10px;
  background-color: #ff4c4c;
  color: #fff;
  border: none;
  border-radius: 0 0 12px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.deconnexion-improbable button:hover {
  background-color: #e63939;
}

/* Icônes improbables */
.element-menu-improbable i {
  font-size: 18px;
  color: #666;
}








/* Fenêtre modale */
.modale-profil-unique {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modale-overlay-unique {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modale-contenu-unique {
  background: #fff;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  z-index: 1001;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* En-tête */
.modale-entete-unique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.bouton-fermer-unique {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* Corps */
.modale-corps-unique {
  padding: 20px 0;
}

.section-informations-unique,
.section-securite-unique,
.section-deconnexion-unique {
  margin-bottom: 20px;
}

.info-profil-unique {
  display: flex;
  
  gap: 15px;
}

.photo-modifiable-unique {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 20px;
  border: 2px solid #007bff;
}



.user-info-unique {
  margin-left: 5px;
  font-family: Arial, sans-serif;
}

.user-info-unique h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.info-item-unique {
  margin-bottom: 1px;
}

.separator-unique {
  border: none;
  border-top: 1px solid #eff0f2;
  margin: 10px 0;
  width: 100%;
}

.info-item-unique span {
  font-size: 14px;
  color: #555;
}

.info-item-unique span strong {
  color: #007bff;
}

/* @media (max-width: 768px) {
  .info-profil-unique {
    flex-direction: column;
    align-items: center;
  }

  .photo-modifiable-unique {
    margin-bottom: 10px;
  }

  .user-info {
    text-align: center;
  }
}
 */










.btn-modifier-unique,
.btn-securite-unique,
.btn-deconnexion-unique {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.btn-securite-unique{
  width: 50%;
}

.btn-deconnexion-unique {
  background: #ff4d4d;
  width: 100%;
}

.btn-modifier-unique:hover,
.btn-securite-unique:hover
 {
  background: #0056b3;
}

.btn-deconnexion-unique:hover {
  background: #750404;
}

.select-theme-unique,
.checkbox-notifications-unique {
  margin-left: 10px;
}





/* Responsivité pour les écrans de taille moyenne (tablettes, par exemple) */
@media (max-width: 1030px) {
  .modale-contenu-unique {
    width: 90%; /* Réduire la largeur de la modale sur les écrans moyens */
    padding: 15px;
  }

  .photo-modifiable-unique {
    width: 190px; /* Réduire la taille de l'image */
    height: 190px;
  }

  .info-profil-unique {
    flex-direction: column; /* Empiler les éléments de manière verticale */
    align-items: center; /* Centrer les éléments */
  }

  .user-info-unique h2 {
    font-size: 20px; /* Réduire la taille du titre */
  }

  .separator-unique {
    margin: 8px 0; /* Réduire l'espace autour du séparateur */
  }

  .btn-modifier-unique,
  .btn-securite-unique,
  .btn-deconnexion-unique {
    padding: 8px 12px; /* Réduire les boutons */
    font-size: 14px;
  }


  .menu-deroulant-improbable {
   
    top: 118px;
    left: 18px;
    width: 290px;
   
  }


}

/* Responsivité pour les petits écrans (mobiles, par exemple) */
@media (max-width: 768px) {
  .modale-contenu-unique {
    width: 95%; /* Modale encore plus petite */
    padding: 10px;
  }

  .photo-modifiable-unique {
    width: 170px; /* Image plus petite sur mobile */
    height: 170px;
  }

  .info-profil-unique {
    flex-direction: column; /* Empiler les éléments verticalement */
    align-items: center; /* Centrer les éléments */
  }

  .user-info-unique h2 {
    font-size: 18px; /* Réduire la taille du titre */
  }

  .separator-unique {
    margin: 6px 0; /* Réduire l'espace autour du séparateur */
  }

  .info-item-unique {
    font-size: 12px; /* Réduire la taille du texte des items */
  }

  .btn-modifier-unique,
  .btn-securite-unique,
  .btn-deconnexion-unique {
    width: 100%; /* Les boutons occupent toute la largeur */
    padding: 10px;
    font-size: 16px;
  }

  .btn-securite-unique {
    width: 100%;
  }

  .btn-deconnexion-unique {
    width: 100%;
  }

  .select-theme-unique,
  .checkbox-notifications-unique {
    margin-left: 0; /* Retirer l'espace à gauche sur mobile */
    margin-top: 10px;
  }


  .menu-deroulant-improbable {
  
    top: 116px;
    left: 18px;
    width: 250px;
    
  }
}


.camera-icon90 {
  position: absolute;
  bottom: 35px;
  right: 15px;
 
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}






/* Styles de la notification */
/* .notification {
  position: fixed;
  top: 20px;
  right: -300px; 
  background-color: #f44336; 
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  z-index: 1000;
  animation: slideIn 0.5s forwards, fadeOut 0.5s 4.5s forwards;
  width: 35%;
}


@keyframes slideIn {
  from {
    right: -300px; 
  }
  to {
    right: 20px; 
  }
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    right: -300px; 
  }
} */




/* Styles de la notification */
.notification {
  position: fixed;
  top: 20px;
  right: -350px; /* Position initiale à l'extérieur de l'écran */
  background-color: #f44336; /* Rouge pour une alerte */
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  z-index: 1000;
  width: 300px;
  animation: slideIn 0.5s forwards, fadeOut 0.5s 4.5s forwards;
}

/* En-tête de la notification */
.notification-header {
  display: flex;
  align-items: center;
   margin-bottom: 10px;
}

/* Icône de l'alerte */
.notification-icon {
  font-size: 16px;
  margin-right: 1px;
}

/* Titre de la notification */
.notification-title {
  font-size: 16px;
  font-weight: bold;
}

/* Description de la notification */
.notification-description {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
}

/* Animation pour faire apparaître de la droite vers la gauche */
@keyframes slideIn {
  from {
    right: -350px; /* Début hors écran */
  }
  to {
    right: 20px; /* Position finale visible */
  }
}

/* Animation pour disparaître */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    right: -350px; /* Retour hors écran */
  }
}









.download-container {
  max-width: 40rem;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.download-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.download-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.download-option-btn {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
  background-color: #e5e7eb;
}

.download-option-btn.selected {
  background-color: #3b82f6;
  color: white;
}

.download-option-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.download-date-section {
  margin-bottom: 1rem;
}

.download-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.download-date-container {
  display: flex;
  gap: 1rem;
}

.download-date-input {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 50%;
}

.download-btn {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
}

.download-btn.active {
  background-color: #dc2626;
  color: white;
}

.download-btn.disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.download-btn:hover:not(.disabled) {
  background-color: #b91c1c;
}


.downHeight{
  background-color: white; /* Fond clair */
  min-height: 80vh; /* Assurer que l'arrière-plan couvre toute la hauteur de la page */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}














.email-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-text {
  font-size: 12px;
  color: #333;
}

.email-input-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.email-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-email-btn,
.save-btn {
  background-color: #067a74;
  color: white;
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-email-btn:hover,
.save-btn:hover {
  background-color: #055c54;
}













.popup-error {
  position: fixed;
  top: 20px;
  left: 5px;
  background-color: rgba(255, 0, 0, 0.9);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.5s ease-out 2.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
