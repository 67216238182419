
.acc1{
    text-align: center;
    color: black;
}
.dawin{
        
        height: 100vh;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),url(/src/css/noir1.jpeg);
        background-size: cover;
        background-position: center;
        padding: 0 5%;   
}

.cieux {
    position: absolute;
	bottom: 10%;
	color: #fff;
}

.cieux p {
	font-size: 60px;
	font-weight: bold;
    color: transparent;
	-webkit-text-stroke: 1px white;
    background: url(/src/css/pattern.png);
	-webkit-background-clip: text;
	background-clip: text;
	background-position: 0 0;
	animation: animate 20s linear 2s infinite alternate;
}

.cieux h2 {
	font-size: 100px;
	margin-left: -17px;
	color: transparent;
	-webkit-text-stroke: 1px white;
    background: url(/src/css/pattern.png);
	-webkit-background-clip: text;
	background-clip: text;
	background-position: 0 0;
	animation: animate 20s linear 2s infinite alternate;
}

@keyframes animate {
	100% {
		background-position: -500px 0;
	}
}

.site-container h1 {
	font-size: 40px;
	font-weight: 500;
}


.ban2{

	display: flex;
	justify-content: space-around;
	
	width: 100%; /* Largeur de la bannière à 100% de la largeur de la page */
 height: 200px; /* Hauteur de la bannière */
 background-image: url('/src/css/ban3.jpg'); /* Chemin vers votre image */
 background-size: cover; /* Redimensionner l'image pour qu'elle couvre la totalité de la bannière */
 background-position: center; /* Centrer l'image */
 text-align: center; /* Aligner le texte au centre si vous en ajoutez */
 color: white; /* Couleur du texte */
align-items: center;



 font-size: 3.25rem;
   font-weight: 700;
   line-height: 3.25rem;
   margin-bottom: 2rem;
   font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
 
}
