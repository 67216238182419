@import './auth.css';
@import './presence.css';
@import './accueil.css';
@import './side.css';
@import './candidat.css';
@import './search.css';
@import './navbar.css';
@import './authV.css';
@import './loader.css';
@import './new.css';
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import './table.css'; 
@import './form.css';
@import './liste.css';
@import './dash.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato' , sans-serif;
}