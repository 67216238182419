.th{
    padding: 5px 55px;
    color: white;
    background-color: #53af57;
}
.tho{
    padding: 5px 55px;
    color: white;
    background-color: #53af57;
}
.to{
    padding: 5px 55px;
}
.tcond{
    padding: 5px 55px;
    color: white;
    background-color: #53af57;
}
.tbilan{
    color: white;
    background-color: #53af57;
}
th, td{
    padding: 1px 1.5px;
    
    
}

.bouttonTable{
    min-width: 400px;
    width: auto;
}
.t{
    background-color: gold;
    text-align: center;
}

.table{
    border-collapse:collapse;
    min-width: 500px;
    width: auto;
    box-shadow: 0 5px rgba(0, 0, 0, 1.5);
    margin:1% auto; 
    border: 1px solid #ddd;   
}
.ta{
    
    width: auto;
    margin:1% auto;
}
.tam{
    min-width: 300px;
    width: auto;
    margin:1% auto;
}
.tah{
    min-width: 200px;
    width: auto;
    margin:1% auto;
}
.taa{
    
    
    width: auto;
    margin:1% auto;
   
    border: 1px solid #ddd;  
}
.taaa{
    
    width: auto;
    margin:1% auto;
   
    border: 1px solid #ddd;  
}


thead tr{
    background-color: gold;
    color: black;
    text-align: center;
}


.ul{
    
    text-align: center;
    justify-content: space-between;
    padding: 2px 5px;
}

.win{
    
    color: transparent;
    text-align: center;


    
    
	
	-webkit-text-stroke: 1px white;
    background: url(/src/css/pattern.png);
	-webkit-background-clip: text;
	background-clip: text;
	background-position: 0 0;
	animation: animate 20s linear 2s infinite alternate;
}   

.bs{
    display: inline-block;
  background-color: rgb(69, 69, 230);
  border-radius: 10px;
  border: 4px double #cccccc;
  color: #eeeeee;
  text-align: center;
  font-size: 13px;
  padding: 1px;
  width: 100px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  
}
.po{
    color: blue;
}

.bs:hover{
    background-color: rgb(77, 134, 213);
}
.bx:hover{
    background-color: green;
}
.bx{
    display: inline-block;
    background-color: red;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: whitesmoke;
    text-align: center;
    font-size: 13px;
    padding: 1px;
    width: 100px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}
.ajouter{
    height: 50px;
    width: 100px;
    background-color: green;
    color: white;
    border: none;
    font-size: 15px;
}
.ajouter2{
    height: 30px;
    width: 80px;
    background-color: green;
    color: white;
    border: none;
    font-size: 12px;
}
.ajouter:hover{
    background-color: rgb(114, 244, 114);
}
.ajouter:active{
    background-color: green;
}
.yello{
    color: blue;
    margin-left: 2rem;
    font-size: 1.5rem;
}
.lin{
    text-decoration: none;
    color: white;
}
.lin:hover{
    color: blue;
}

.mod{
    height: 20px;
    width: 20px;
}
.mod:hover{
    height: 25px;
    width: 25px;
}


.modAF{
    height: 100%;
    width: 100%;
}
.modAF:hover{
    height: 50%;
    width: 50%;
}


.bian{
display: flex;
justify-content: space-between;
    align-items: center;
    
    
}
.biou{
    align-items: center;
    padding: 10px 70px;
}
.itec{
    display: flex;
    justify-content: space-between;
}
.etat{
    
    text-align: center;
    color: white;
}
.totale{
    color: red;
}
/* .gain1{
    color: rgba(0, 0, 255, 0.526);
  
    
}
.gain2{
    color: rgb(82, 2, 2);
}
.affich{
    text-align: center;
}
.gain{
    color: white;
} */


.affich {
    text-align: center;
    /* margin-top: 20px; */
}

.gain {
    color: white;
    font-size: 24px;
   /*  margin-bottom: 10px; */
}

.result-card {
    margin-bottom: 10px;
    display: inline-block;
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.gain1 {
    background-color: rgba(244, 244, 250, 0.993);
    color: blue;
   /*  border: 2px solid blue; */
}

.gain2 {
    background-color: rgb(245, 238, 238);
    color: darkred;
    /* border: 2px solid darkred; */
}





.h{
    color: white;
    margin-left: 260px;
    
}

.ho{
    margin-left: 150px;
    color: white;
}
.ha{
    margin-left: 55px;
    color: white;
}
.tol{
    color: white;
}
.omega{
    display: flex;
    justify-content:space-around;
}
.ome{
   text-align: center;
   color: white;
}
.to{
    color: rgb(7, 217, 206);
}
.ali{
    text-align: center;
    color: white;
    font-size: 25px;
}
.recadre {
    align-items: center;
}
.title{
    text-align: center;
    color: white;
}
.space{
    height: 10vh;
}
.scroll-bg{
    background-color: transparent;
    width: 100%;
    
    padding: 30px;
}
.scroll-div{
    background-color: transparent;
    width: 100%;
    height: 150px;
    overflow: hidden;
    overflow-y:scroll  ;
   
}
.scroll-div::-webkit-scrollbar{
width: 0;
background: transparent;
}



.scroll-divP{
    background-color: transparent;
    width: 100%;
    height: 100px;
    overflow: hidden;
    overflow-y: scroll ;
    

}
.scroll-divR{
    background-color: transparent;
    width: 100%;
    height: auto;
   max-height: 60vh;
    overflow: hidden;
    overflow-y: scroll ;
    

}
.scroll-divP::-webkit-scrollbar{
    display: none;
}
.text-danger2{
    color: rgba(249, 240, 240, 0.759);
    font-size: 22px;
    
}
.chk{
    height: 15px;
    width: 16px;
}