
.form {
 
  
  width:400px;
 margin:0 auto;
 margin-top:10%;
}
h1{
  width: 38%;
  margin: 0 auto;
  padding-bottom: 10px;
 }
 
 .select, .type {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #ccc;
  box-sizing: border-box;
  background: transparent;
 }
 .ob{
  background: transparent;
  box-sizing: border-box;
  color: white;
 }
 .button {
  background-color: green;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
 }
 .enregistrercond{
  background-color: green;
  color: white;
  padding: 14px 20px;
  margin: 8px 500px;
  border: none;
  cursor: pointer;
  width: 20%;
  
 }

 

 .button:hover {
  background-color: #53af57 ;
  color: white;
  border: 1px solid #53af57;
 }
 .button:active{
  background-color: green ;
 }
 .enregistrercond:hover{
  background-color: #53af57 ;
  color: white;
  border: 1px solid #53af57;
 }
 .enregistrercond:active{
  background-color: green ;
 }
 .nom,.password,.con,.select,.id,.prenom,.contact,.sexe,.age,.montant,.date,.statut,.adresse,.textarea,option,.nomo {
  color: black;
 }
 .select {
  background-color: transparent;
 }
.text-danger {
  color: red;
  text-align: center;
}
.mo,.da{
  color: black;
}

.ide{
  text-align: center;
  font-size: 35px;
  color: white;
}
.bande{
  background-color: rgb(6, 122, 116);
}

.boua{
  height: 50px;
  width: 100px;
  background-color:rgb(233, 226, 226);
}
.boun{
  height: 50px;
  width: 100px;
  background-color: transparent;
  border: none;
}
.boun:hover{
  background-color:#ccc ;
}
.upo{
  color: grey;
  pointer-events: none;
    cursor: default;
}