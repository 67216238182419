.pre{
    display: inline-block;
  background-color: green;
  border-radius: 10px;
  border: 4px double #cccccc;
  color: #eeeeee;
  text-align: center;
  font-size: 24px;
  padding: 80px;
  width: 400px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 110px;
}

.cod:hover{
    background-color: rgb(238, 100, 100);
}
.pre:hover{
    background-color: rgb(88, 230, 88);;
}

.cod{
    display: inline-block;
    background-color: red;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 24px;
    padding: 80px;
    width: 400px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 110px;
}

.rec{
    display: inline-block;
  background-color: green;
  border-radius: 10px;
  border: 4px double #cccccc;
  color: #eeeeee;
  text-align:center;
  font-size: 13px;
  padding: 70px;
  width: 300px;
  height: 100px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 40px;
}
.rec:hover{
    background-color: rgb(88, 230, 88);
}
.rev{
    display: inline-block;
    background-color: red;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 13px;
    padding: 70px;
    width: 300px;
    height: 100px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 40px;
    
}
.rev:hover{
    background-color: rgb(238, 100, 100);
}

.red{
    display:inline-block;
    background-color: blue;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 13px;
    padding: 70px;
    width: 300px;
    height: 100px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 40px;
}
.red:hover{
    background-color: rgb(153, 149, 217);
}
.red:active{
    background-color: blue;
}

.rea{
    display: inline-block;
    background-color: green;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 13px;
    padding: 70px;
    width: 300px;
    height: 100px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 40px;;
}
.redmi{
    display: inline-block;
    background-color: green;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 13px;
    padding: 70px;
    width: 300px;
    height: 100px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 40px;;
}
.rea:hover{
    background-color: rgb(189, 211, 44);
}
.rea:active{
    background-color: green;
}
.ope{
    display: flex;
}
.reddi{
    display: inline-block;
    background-color: blue;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 22px;
    padding: 80px;
    width: 400px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 100px;
}
.reddi:hover{
    background-color: rgb(153, 149, 217);
}


.readi{
    display: inline-block;
    background-color: yellowgreen;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: #eeeeee;
    text-align: center;
    font-size: 22px;
    padding: 80px;
    width: 400px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 100px;
}
.readi:hover{
    background-color: rgb(189, 211, 44);
}
.buto{
    display:inline-flex;
    
    
    
}
.pesen{
    text-align: center;
    color: blue;
}
.af{
     text-align: center;
     color: white;
}

.ess{
    background-color: green;
    color: white;
    padding: 14px 20px;
    margin: 8px 500px;
    border: none;
    cursor: pointer;
    width: 20%;
}
.ess:hover{
    background-color: #53af57 ;
  color: white;
  border: 1px solid #53af57;
}