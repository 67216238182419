.dashboard-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 15px;
    background-color: whitesmoke;
    margin-bottom: 20px;
  }
  
  .dashboard-card {
    background-color: #f9f9f9;
    padding: 40px;
 width: 400px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .card-title {
    text-align: center;
    font-size: 20px;
   
    margin-bottom: 5px;
  }
  
  .card-content {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  /* Styles pour la section des statistiques générales */
  .statistiques-generales {
    background-color: rgb(129, 177, 142);
    box-shadow: 0 8px 4px rgba(2, 90, 2, 0.75); 
   
  }
  
  /* Styles pour la section des statistiques spécifiques aux candidats */
  .statistiques-candidats {
  background-color: rgb(226, 165, 206);
    box-shadow: 0 8px 4px rgb(121, 3, 82); 
  }
  
  /* Styles pour la section des statistiques spécifiques aux annexes */
  .statistiques-annexes {
    background-color: #f5c764; /* Jaune clair */
    box-shadow: 0 8px 4px #91660b; 
  }
  
  /* Styles pour la section des alertes et notifications */
  .alertes-notifications {
    background-color: #749be9; /* Gris clair */
    box-shadow: 0 8px 4px #0f4396; 
  }
  
  /* Styles pour la section du résumé des activités récentes */
  .resume-activites-recentes {
    background-color: #dc9191;
    box-shadow: 0 8px 4px  #e93939  /* Gris légèrement plus foncé */
  }
  
  .resume-activites-suv {
    background-color: #de7ae4;
    box-shadow: 0 8px 4px #86058c  /* Gris légèrement plus foncé */
  }
  
  .resume-activites-etat {
    background-color: grey;
    box-shadow: 0 8px 4px rgb(16, 15, 15)  /* Gris légèrement plus foncé */
  }



  .wax{
   /* 
    background-image: url(/src/css/header-bg.jpg);*/
    background-color: whitesmoke;
    background-attachment: fixed;
    min-height: 100vh;
    background-size: cover;
    justify-content: center;
    align-items: center;

    }

    .waxx{
      
       background-image: url(/src/css/header-bg.jpg);
  
       background-attachment: fixed;
       min-height: 100vh;
       background-size: cover;
       justify-content: center;
       align-items: center;
   
       }

    .gh{
      background-size: cover;
      background-position: center;
      color: #fff;
      text-align: center;
      padding: 10rem 0;
    }
.vost{
  padding: 20px;
  text-align: center;
  color: black;
  font-size: 3.25rem;
    font-weight: 700;
    line-height: 3.25rem;
    margin-bottom: 2rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
}
.graph{

  display: flex;
  justify-content: space-between; /* Pour ajouter un espace égal entre les éléments */
  align-items: center; 
  margin-bottom: 30px;
}

    .cardDash {
      width: 400px; /* Définir la largeur de la carte selon vos besoins */
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      
        flex: 1; /* Pour que les cartes occupent une part égale de l'espace disponible */
        margin-right: 20px;
    
    }
    
    .canvas-container {
      width: 100%;
      height: 350px; /* Définir la hauteur du conteneur du canvas selon vos besoins */
    }
    
    .canvas-container canvas {
      width: 100% !important;
      height: 100% !important;
    }
    

    /* Pour les dispositifs de taille moyenne à grande */
@media screen and (min-width: 768px) {
  .dashboard-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  
  .dashboard-card {
    width: 100%; /* Utiliser toute la largeur disponible */
  }
}

/* Pour les dispositifs de petite taille */
@media screen and (max-width: 767px) {
  .dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes */
    gap: 20px; /* Espacement entre les éléments */
  }
  
  .dashboard-card {
    width: 80%; /* Utiliser toute la largeur disponible */
    margin-left: 20px;
    margin-bottom: 15px;
  }
}


@media screen and (max-width: 395px) {
  .dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes */
    gap: 20px; /* Espacement entre les éléments */
  }
  
  .dashboard-card {
    width: 80%; /* Utiliser toute la largeur disponible */
    margin-left: 15px;
    margin-bottom: 15px;
  }
}


/* Pour les petits écrans */
@media screen and (max-width: 767px) {
  .graph {
    flex-direction: column; /* Afficher les graphiques verticalement */
  }
  
  .cardDash {
    width: 100%; /* Utiliser toute la largeur disponible */
    margin-bottom: 20px; /* Ajouter un espace entre les graphiques */
    padding: 10px; /* Ajouter du rembourrage pour séparer les graphiques */
  }

  .canvas-container canvas {
    width: 100%;
    max-width: 100%;
    height: auto;
    font-size: 12px; /* Réduire la taille de la police pour les petits écrans */
  }
  
  .chart-title {
    font-size: 16px; /* Ajuster la taille de la police du titre du graphique */
  }
  .vost{
    
  font-size: 1.25rem;
  line-height: 1.25rem;
  }
  .vost1{
    font-size: 16px;
    text-align: center;
    color: #91660b;
  }
  .vost2{
    font-size: 16px;
    text-align: center;
    color: #91660b;
  }
}


.arrow-icon {
  color: rgb(6, 122, 116); /* Couleur bleue */
  font-size: 3.25rem; /* Taille de la police de l'icône */
}

.arrow-icon2 {
  color: rgba(27, 27, 28, 0.242); /* Couleur bleue */
  font-size: 3.25rem; /* Taille de la police de l'icône */
}


.waxi{
  display: flex;
}
.opoto{
  position: absolute;
  margin-top: 300px;
  padding: 10px;
}

.opata{
  position: absolute;
  margin-top: -666px;
  margin-left: 900px;
  padding: 10px;
}

.rich{
  display: flex;
  justify-content: space-around;
}