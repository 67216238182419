.bilan-depense {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 1.5;
    display: flex; /* Utiliser flexbox pour aligner les éléments horizontalement */
  justify-content: space-between; 
  }

  .texte-depense {
    flex-grow: 1; /* Pour que le texte prenne toute la largeur disponible */
  }
   .txt{
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   }
  .montant {
    font-weight: bold;
    color: #2ecc71; /* Couleur verte pour le montant */
  }

  .icone-modifie {
    position: relative; /* Positionnement relatif pour l'icône */
  }
  
  .icone-modifie::before {
    content: "\270E"; /* Code du caractère de crayon */
    position: absolute;
    top: 220%;
    right: 2px;
    color: rgb(35, 161, 234);
    transform: translateY(-50%);
    font-size: 30px;
  }
  

  .icone-modifier1 {
    position: relative; /* Positionnement relatif pour l'icône */
  }
  
  .icone-modifier1::before {
    content: "\270E"; /* Code du caractère de crayon */
    position: absolute;
    top: 200%;
    right: 2px;
    color: rgb(35, 161, 234);
    transform: translateY(-50%);
    font-size: 30px;
  }

  .icone-modifier2 {
    position: relative; /* Positionnement relatif pour l'icône */
  }
  
  .icone-modifier2::before {
    content: "\270E"; /* Code du caractère de crayon */
    position: absolute;
    top: 80%;
    right: 2px;
    color: rgb(35, 161, 234);
    transform: translateY(-50%);
    font-size: 30px;
  }


  .libelle {
    font-style: italic;
    
  }
  
  .date {
    color: #722f37; /* Couleur bleue pour la date */
  }
  
  .profil {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .icone-profil {
    font-size: 24px;
    margin-right: 10px;

  }

  .carD{
   
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: rgb(6, 122, 116);
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.268);
  }

  .auI{
    font-style: italic;
    font-size: 10px;
    color: rgb(14, 214, 224);
  }

  .inPo{
    font-size: 1rem;
    
    
    margin-bottom: 2rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  }

  .inN{
    font-size: 1.2rem;
    font-weight: 300px;
    font-style: italic;
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 4rem;
  }
  
  .infos-utilisateur p {
    margin: 0;
    color: white;
  }

  .icone-modifier {
    position: relative; /* Positionnement relatif pour l'icône */
  }
  
  .icone-modifier::before {
    content: "\270E"; /* Code du caractère de crayon */
    position: absolute;
    top: 300%;
    right: 2px;
    color: rgb(35, 161, 234);
    transform: translateY(-50%);
    font-size: 30px;
  }


  

  
.alie{
  text-align: center;
  color: white;
  font-size: 25px;
}




  .modalSup, .overlaySup{
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 30;
}

.overlaySup{
    background: rgba(49, 49, 49, 0.083);
}

.modal-contentSup{
  text-align:center;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    color: black;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    z-index: 30;
}

.supD{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-around;
}
.supA{
  width: 100px;
  height: 30px;
  color: green;
}
.supC{
  width: 100px;
  height: 30px;
  color: red;
}

.modifier-btn2 {
  background-color: grey;
  color: #fff;
  padding: 8px 16px;
  border: none;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.modifier-btn2:hover {
  background-color: gray;
}

.rechBar2{
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 300px;
}

.butonSF2 {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  width: 120px;
}

.barContener2 {
  /* Afficher les éléments en colonne sur les petits écrans */
  flex-direction: column; /* Afficher les éléments en colonne sur les petits écrans */
  align-items: center; /* Centrer les éléments */
 
  justify-content: space-around;
  align-items: center;
 
  padding: 10px;
  border-radius: 5px;
}

.butonSF2 {
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.avt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 17, 17, 0.116);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.rin {
  position: relative;
}

.chm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
}